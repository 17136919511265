export default {
    STAGING_HINT_MESSAGE: 'Staging enabled.',
    STAGING_HINT_DISABLE_MESSAGE: 'Disable Findologic',
    IDENTITY_NAME: 'findologic-identity',
    IDENTITY_IN_ERROR_CASE: '00000000-0000-0000-0000-000000000000',
    STAGING_FLAG_NAME: 'findologic-staging',
    FALLBACK_FLAG_NAME: 'findologic-fallback-start',
    STORAGE_KEY_ENV: 'findologic-env',
    STORAGE_KEY_PREVIEW_BRANCH: 'findologic-preview-branch',
    STORAGE_KEY_DI_VERSION: 'findologic-di-version',
    FALLBACK_EXPIRATION_INTERVAL: 10 * 60 * 1000,
    ERROR_TRACKER_CALLBACK_TAG: 'callback',
    STATUS_INACTIVE: 'inactive',
    STATUS_ACTIVE: 'active',
    STATUS_CANCELLED: 'cancelled',
    SMART_SUGGEST_TYPE_RESULT_V3: 'result_v3',
    SHOP_URL: 'shopurl',
    SHOP_KEY: 'shopkey',
    QUERY_PARAMS_REQUEST_ID: 'requestId',
    INIT_RETRY_INTERVAL_MS: 500,
    MAX_INIT_RETRIES: 10,
    DING_SOURCES: ['js/app.js', 'js/chunk-vendors.js'],
    DING_STYLES: ['css/chunk-vendors.css'],
    ENV_PROD: 'prod',
    ENV_PREVIEW: 'preview',
    ENV_DEV: 'dev',
    DI_VERSION_CLASSIC: 'classic',
    DI_VERSION_DING: 'ding',
    GUIDED_SHOPPING_MARKER_ATTRIB: 'data-is-guided-shopping-filter',
    INSTANT_FRONTEND_WRAPPER_CLASS: 'fl-instant-frontend',
    /**
     * Attribute to use for limiting navigation result sets using the category page.
     */
    ATTRIB_CAT_URL: 'cat_url',
};
