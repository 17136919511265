import FlUtils from '../../utils/utils';
import Utils from '../../../tscoba/src/Common/Utils.ts';
import BaseShopSystem from './base';
import { cloneDeep as _cloneDeep, merge as _merge } from 'lodash-es';
import { NormalizedVariant } from '../../../tscoba/src/Common/NormalizedVariant.ts';
import globalShopConfig from '../../config';

const ShopwareBase = (function () {
    /* Public */
    return {
        NAME: 'Shopware',
        VERSION: 1,

        /**
         * Appends shopsystem specific input fields to the form
         *
         * @param {JSON} item
         * @param {Object} form
         */
        appendSpecificItemsToForm(item, form) {
            // PRODUCT
            if (item.hasOwnProperty('identifier') && item.block === this.BLOCKS.PRODUCT) {
                this.appendInput(form, 'identifier', item.identifier);
            }

            // CAT
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.CAT) {
                this.appendInput(form, 'catFilter', item.label);
            }

            // Vendor
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.VENDOR) {
                this.appendInput(form, 'attrib[vendor][]', item.label);
            }

            return this;
        },

        fixFormAction(formAction) {
            const shopware3Pattern = /\/sViewport,search$/;

            let fixedFormAction;

            if (formAction.match(shopware3Pattern)) {
                fixedFormAction = formAction.replace(shopware3Pattern, '/sViewport,SwpFindologic/sAction,search');
            } else {
                fixedFormAction = formAction;
            }

            return fixedFormAction;
        },

        beforeOperation(operation, params) {
            return _merge(_cloneDeep(params), { properties: ['quantity', ...params.properties] });
        },

        /**
         * @param item {BaseItem}
         * @param imageProperties {string[]}
         * @return {NormalizedVariant[]}
         */
        // eslint-disable-next-line no-unused-vars
        getNormalizedVariants(item, imageProperties) {
            if (item.ordernumbers.length < 1) {
                // The ordernumber identifies products for cart functionality.
                // If it's not available, no cart.
                return [];
            }

            const stockInt = (baseItem) => parseInt(baseItem.properties['quantity'] ?? null, 10);
            const extraImages = Utils.getAllItemImages(item, imageProperties);

            if (!item.variants || item.variants.length === 0) {
                return [new NormalizedVariant(item.id, item.ordernumbers[0], isNaN(stockInt(item)) ? null : stockInt(item), extraImages)];
            }

            return item.variants.map(
                (variant) =>
                    new NormalizedVariant(
                        variant.id,
                        variant.ordernumbers[0] || null,
                        isNaN(stockInt(variant)) ? null : stockInt(variant),
                        Utils.getAllItemImages(variant, imageProperties),
                        variant.url,
                        variant?.attributes,
                        globalShopConfig.instantFrontend.productListingPage.productCard.variantAttributes
                    )
            );
        },

        supportsAddToCart: true,

        addToCart(normalizedVariant, quantity = 1) {
            const form = document.createElement('form');
            form.action = '/checkout/addArticle';
            form.method = 'POST';

            FlUtils.appendHiddenFieldToForm(form, 'sAdd', normalizedVariant.ordernumber);
            FlUtils.appendHiddenFieldToForm(form, 'sQuantity', quantity);

            // Shopware requires CSRF tokens for cart operations. Luckily, they
            // can be found throughout the shop, and identified by the input
            // element's name being __csrf_token.
            FlUtils.appendHiddenFieldToForm(form, '__csrf_token', document.querySelector('[name=__csrf_token]').value);

            document.body.append(form);
            form.submit();
        },
    };
})();

FlUtils.extend(ShopwareBase, BaseShopSystem);
export default ShopwareBase;
