import jQuery from '../utils/fl-jquery';
import utils from '../utils/utils';
import shopConfig from '../config';
import constants from '../constants';
import { identity, logger, url as urlUtils } from '@findologic/js-common';
import ShopSystem from './shopsystem/shopsystem';

let shopSystem;

/**
 * @type {string} Prefix for shopping guide names in the URL.
 */
const HASH_PREFIX = 'guide:';

const FlWizard = {
    wizardClass: 'fl-wizard',
    autocompleteProxy: null,
    autocompleteProxyQueryParameters: null,
    overlay: null,
    wizardQueryPrefix: 'flwizard_',
    tabs: null,
    formObj: null,
    searchField: null,
    preventInitialization: false,
};

/**
 * Init the Wizard
 *
 * @param {Function} callback The callback to execute after init is done, or NULL for no callback
 */
FlWizard.init = function (callback) {
    shopSystem = ShopSystem.forConfig(shopConfig);

    if (FlWizard.preventInitialization) {
        logger.info('Prevented initialization of Guided Shopping');

        return;
    }

    FlWizard.autocompleteProxy = shopConfig.autocompleteProxy;
    /*
     * Extract and parse the query string from the autocomplete proxy URL,
     * f.ex. Veyton uses autocomplete.php?page=vt_findologic_autocomplete
     */
    FlWizard.autocompleteProxyQueryParameters = urlUtils.parseQueryString(urlUtils.getQueryString(FlWizard.autocompleteProxy));
    /* Now remove the query string as we have extracted the parameters */
    FlWizard.autocompleteProxy = FlWizard.autocompleteProxy.replace(/\?.*$/, '');

    // Get the first searchField in the shop and the associated form object
    FlWizard.searchField = utils.getSingleSearchFieldElement();
    FlWizard.formObj = jQuery(FlWizard.searchField).closest('form');

    if (jQuery('div.fl-wizard-dialog').size() == 0) {
        FlWizard.overlay = jQuery('<div/>').addClass('fl-wizard-dialog').css('display', 'none');
        jQuery('body').append(FlWizard.overlay);
    }

    FlWizard.waitForLaunch();

    if (typeof callback === 'function') {
        callback();
    }
};

FlWizard.waitForLaunch = function () {
    function launchIfHashMatches() {
        // String.substring(1) always works, even if the string is shorter than one character.
        const currentHash = window.location.hash.substring(1);

        if (currentHash.indexOf(HASH_PREFIX) > -1) {
            FlWizard.launch(currentHash.substring(HASH_PREFIX.length));
        }
    }

    // Check if a shopping guide has been launched before it was ready by checking the URL hash.
    launchIfHashMatches();

    // For legacy compatibility, wait for click events on the appropriately classed shopping guide links.
    jQuery(document).on('click.fl-guided-shopping-link', `.${FlWizard.wizardClass}`, function () {
        // The link's href looks like this: "#Wizard-Name", remove everything before the hash so we get the
        // Wizard name.
        const wizard = jQuery(this)
            .attr('href')
            .replace(/^.*?(#|$)/, '');

        FlWizard.launch(wizard);

        return false;
    });

    // Wait for hash changes to detect new-style shopping guide invokations.
    jQuery(window).on('hashchange.fl-hashchange-guided-shopping', launchIfHashMatches);
};

FlWizard.launch = function (encodedName) {
    // decodeURIComponent does not decode '+' to ' ', so do this manually first.
    encodedName = encodedName.replace(/\+/g, ' ');
    const decodedName = decodeURIComponent(encodedName);

    const wizardParameters = {
        wizard: decodedName,
    };

    const wizardUrl = `${FlWizard.autocompleteProxy}?${jQuery.param(
        jQuery.extend(true, {}, FlWizard.autocompleteProxyQueryParameters, wizardParameters)
    )}`;

    const currentTab = 0;

    return import('../utils/fl-jquery-ui-module').then(() => {
        FlWizard.loadContent(FlWizard.overlay, wizardUrl, currentTab, function () {
            FlWizard.overlay.dialog({
                autoOpen: true,
                modal: true,
                width: '700px',
                title: decodedName,
                dialogClass: 'fl-wizard-dialog-parent',
                close() {
                    window.location.hash = '';
                },
            });
        });
    });
};

/**
 * Load the Wizard content through AJAX
 *
 * @param {Object} container The container to display the loaded content in
 * @param {String} url The URL to send the AJAX request to
 * @param {int} currentTab The current tab, to be reactivated after loading is done
 * @param {Function} callback The callback to execute after loading is done, or NULL for no callback
 */
FlWizard.loadContent = function (container, url, currentTab, callback) {
    const queryString = urlUtils.getQueryString(url);
    const urlParameters = urlUtils.parseQueryString(queryString);

    /* Send form parameters along with the AJAX request, f.ex. plentymarket's multishop_id */
    const formParameters = utils.getFormValues(FlWizard.formObj);

    /* Get rid of query parameter from the search-field */
    const queryParameterName = FlWizard.searchField.attr('name');
    delete formParameters[queryParameterName];

    /* Merge the form and the URL parameters into, making sure the URL parameters have preference */
    const mergedParameters = jQuery.extend(true, {}, formParameters, urlParameters);

    /*
     * Some autocomplete proxies only pass the 'query', 'q' or 'keywords' parameter through to FINDOLOGIC so
     * serialize all parameters and send them through 'query' / 'q' / 'keywords'
     */
    let realUrlParameters = {};
    realUrlParameters.q = realUrlParameters.query = realUrlParameters.keywords = JSON.stringify(mergedParameters);

    realUrlParameters.requestId = identity.createUUID();

    /*
     * Additionally pass the parameters from the form as actual GET parameters, f.ex. plentymarket's autocomplete
     * proxy needs 'multishop_id' and 'customer_class'
     *
     * Make sure that the 'realUrlParameters' have precedence
     */
    realUrlParameters = jQuery.extend(true, {}, FlWizard.autocompleteProxyQueryParameters, formParameters, realUrlParameters);

    if (!shopConfig.useAutocompleteProxy) {
        realUrlParameters.shopkey = shopConfig.shopkey;
    }

    /* Remove the query string from the URL (everything after the ?) so we can append our own query string */
    const actualUrl = `${url.replace(/\?.*$/, '')}?${jQuery.param(realUrlParameters)}`;

    const successCallback = function (data) {
        container.html(data);

        FlWizard.fixTabsForBaseTag(container.find('#flFilterContainer .fl-wizard-tab-navigation'));

        FlWizard.tabs = container.find('#flFilterContainer').tabs({
            active: currentTab,
            activate: FlWizard.setNavigationButtonStatus,
        });

        jQuery('p#wizard-next-step a')
            .button({ icons: { secondary: 'ui-icon-seek-next' } })
            .click(function () {
                if (!jQuery(this).hasClass('wizard-step-inactive')) {
                    const currentTab = FlWizard.tabs.tabs('option', 'active');
                    const nextTab = currentTab + 1;
                    FlWizard.tabs.tabs({ active: nextTab });
                }

                return false;
            });

        jQuery('p#wizard-previous-step a')
            .button({ icons: { primary: 'ui-icon-seek-prev' } })
            .click(function () {
                if (!jQuery(this).hasClass('wizard-step-inactive')) {
                    const currentTab = FlWizard.tabs.tabs('option', 'active');
                    const previousTab = currentTab - 1;
                    FlWizard.tabs.tabs({ active: previousTab });
                }

                return false;
            });

        jQuery('p#wizard-show-results a')
            .button({ icons: { primary: 'ui-icon-search' } })
            .click(function () {
                // Delete query from all search-fields.
                utils.getSearchFieldElements().val('');

                FlWizard.appendParametersToForm(showWizardResultsParameters, FlWizard.formObj, null);
                FlWizard.fixFormActionForShopsystem(shopSystem, FlWizard.formObj);
                FlWizard.formObj.submit();
                FlWizard.overlay.dialog('close');

                return false;
            });

        /*
         * setNavigationButtonStatus is actually a jQuery event callback so wrap the tabs in event.target just like
         * jQuery likes it
         */
        FlWizard.setNavigationButtonStatus({ target: FlWizard.tabs }, null);

        /* Catch filter links (text, multiselect, color) */
        jQuery(container)
            .find('a:not(.do-not-bind-wizard)')
            .click(function () {
                const currentTab = FlWizard.tabs.tabs('option', 'active');

                /* Extract the query string from this link so it can be submitted through AJAX */
                const queryString = urlUtils.getQueryString(this.href);
                const actualUrl = FlWizard.autocompleteProxy + queryString;

                FlWizard.loadContent(container, actualUrl, currentTab, null);

                return false;
            });

        /* Catch range-slider forms */
        jQuery(container)
            .find('.flRangeSlider')
            .submit(function () {
                const currentTab = FlWizard.tabs.tabs('option', 'active');

                /* Send the range slider's form parameters through AJAX */
                const actualUrl = `${FlWizard.autocompleteProxy}?${jQuery(this).serialize()}`;
                FlWizard.loadContent(container, actualUrl, currentTab, null);

                return false;
            });

        /* Catch select filter */
        jQuery(container)
            .find('.flFilterBox select')
            .removeAttr('onchange')
            .change(function () {
                const currentTab = FlWizard.tabs.tabs('option', 'active');

                /* The select/option's value is a full URL, extract the query string so it can be send through AJAX */
                const queryString = urlUtils.getQueryString(jQuery(this).val());
                const actualUrl = FlWizard.autocompleteProxy + queryString;

                FlWizard.loadContent(container, actualUrl, currentTab, null);

                return false;
            });

        if (typeof callback === 'function') {
            callback();
        }
    };

    let dataType;
    if (!shopConfig.useAutocompleteProxy) {
        dataType = 'jsonp';
    } else {
        dataType = 'json';
    }

    jQuery.ajax({
        url: actualUrl,
        success: successCallback,
        dataType,
    });
};

/**
 * Fix the search form action if required for this shop system
 *
 * The action attribute will be fixed in place
 *
 * @param {Object} shopSystem The shop system object
 * @param {jQuery} formObject The form object
 */
FlWizard.fixFormActionForShopsystem = function (shopSystem, formObject) {
    const action = formObject.attr('action');
    if (action !== undefined) {
        const fixedAction = shopSystem.fixFormAction(action);
        formObject.attr('action', fixedAction);
    }
};

/**
 * Fix the href of the tab links so a base tag doesn't break the tabs
 *
 * When the page the Wizard is used in has a <base /> tag jQuery will try to load the tabs according to
 * the base. For example:
 *
 * - The base tag is <base href="http://www.example.com" />
 * - The tab is linked to the tab pane 'question', the tab will then have the link <a href="#question" />
 * - Normally when the tab is clicked it will then show the corresponding tab pane
 * - However when there is a base tag jQuery will resolve the tab link to be http://www.example.com#question
 * - jQuery will then load this URL
 * - Loading URLs is actually meant to support AJAX content for tabs where the tab would have a link like
 * <a href="http://www.example.com/content" />
 *
 * This method will fix the tab links by prepending the current URL, thus making the tab link an absolute link
 * with a hash for the tab pane, ie. http://www.shop.com/#question . jQuery then knows not to link load this link
 *
 * This is mostly taken from http://tjvantoll.com/2013/02/17/using-jquery-ui-tabs-with-the-base-tag/
 *
 * @param {Object} tabContainer The element containing the tab links
 */
FlWizard.fixTabsForBaseTag = function (tabContainer) {
    jQuery(tabContainer)
        .find('a')
        .each(function () {
            const href = jQuery(this).attr('href');
            const newHref = `${window.location.protocol}//${window.location.hostname}${window.location.pathname}${window.location.search}${href}`;

            if (href.indexOf('#') == 0) {
                jQuery(this).attr('href', newHref);
            }
        });
};

/**
 * Append parameters to a form
 *
 * @param {Object} parameters The parameters to append
 * @param {Object} form The form to append to
 * @param {Object} prefix The prefix to append to the keys, NULL if no prefix is wanted
 */
FlWizard.appendParametersToForm = function (parameters, form, prefix) {
    jQuery.each(parameters, function (key, value) {
        const type = jQuery.type(value);
        const prefixedKey = prefix == null ? key : `${prefix}[${key}]`;
        if (type == 'object' || type == 'array') {
            FlWizard.appendParametersToForm(value, form, prefixedKey);
        } else {
            const attributes = {
                type: 'hidden',
                name: prefixedKey,
            };
            attributes[constants.GUIDED_SHOPPING_MARKER_ATTRIB] = true;
            const input = jQuery('<input/>').attr(attributes).val(value);
            form.append(input);
        }
    });
};

/**
 * Set the navigation button (previous, next) status depending on the current tab
 *
 * @param {Event} event
 * @param {Object} ui
 */
FlWizard.setNavigationButtonStatus = function (event) {
    const currentTab = jQuery(event.target).tabs('option', 'active');

    if (currentTab > 0) {
        jQuery('p#wizard-previous-step a').button('option', 'disabled', false);
    } else {
        jQuery('p#wizard-previous-step a').button('option', 'disabled', true);
    }

    const numTabs = jQuery(event.target).find('>ul >li').size();
    if (currentTab < numTabs - 1) {
        jQuery('p#wizard-next-step a').button('option', 'disabled', false);
    } else {
        jQuery('p#wizard-next-step a').button('option', 'disabled', true);
    }
};

export default FlWizard;
