import FlUtils from '../../utils/utils';
import BaseShopSystem from './base';
import { cloneDeep as _cloneDeep, merge as _merge } from 'lodash-es';
import { NormalizedVariant } from '../../../tscoba/src/Common/NormalizedVariant.ts';
import Utils from '../../../tscoba/src/Common/Utils.ts';
import globalShopConfig from '../../config';

const Shopware6 = (function () {
    /* Public */
    return {
        NAME: 'Shopware 6',
        VERSION: 1,

        /**
         * Appends shopsystem specific input fields to the form
         *
         * @param {JSON} item
         * @param {Object} form
         */
        appendSpecificItemsToForm(item, form) {
            // PRODUCT
            if (item.hasOwnProperty('identifier') && item.block === this.BLOCKS.PRODUCT) {
                this.appendInput(form, 'identifier', item.identifier);
            }

            // CAT
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.CAT) {
                this.appendInput(form, 'attrib[cat][]', item.label);
            }

            // Vendor
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.VENDOR) {
                this.appendInput(form, 'attrib[vendor][]', item.label);
            }

            return this;
        },

        beforeOperation(operation, params) {
            return _merge(_cloneDeep(params), { properties: ['availableStock', ...params.properties] });
        },

        /**
         * @param item {BaseItem}
         * @param imageProperties {string[]}
         * @return {NormalizedVariant[]}
         */
        getNormalizedVariants(item, imageProperties) {
            const ordernumber = item.ordernumbers.length > 0 ? item.ordernumbers[0] : null;
            const stockInt = (baseItem) => parseInt(baseItem.properties['availableStock'] ?? null, 10);
            const extraImages = Utils.getAllItemImages(item, imageProperties);

            if (!item.variants || item.variants.length === 0) {
                return [new NormalizedVariant(item.id, ordernumber, isNaN(stockInt(item)) ? null : stockInt(item), extraImages)];
            }

            return item.variants.map(
                (variant) =>
                    new NormalizedVariant(
                        variant.id,
                        variant.ordernumbers[0] || null,
                        isNaN(stockInt(variant)) ? null : stockInt(variant),
                        Utils.getAllItemImages(variant, imageProperties),
                        variant.url,
                        variant?.attributes,
                        globalShopConfig.instantFrontend.productListingPage.productCard.variantAttributes
                    )
            );
        },

        /**
         * Shopware 6 carts require CSRF tokens. It's not possible to re-use tokens
         * from other forms, because they are form-bound, and not all pages contain
         * forms. It's only possible to generate new CSRF tokens as a client if the
         * CSRF mode is "ajax" (as opposed to the default "twig").
         *
         * To change the CSRF mode in a Shopware 6 shop, change the property storefront.csrf.mode
         * to "ajax" in vendor/shopware/storefront/Resources/config/packages/storefront.yaml
         * and clear the cache.
         */
        get supportsAddToCart() {
            return window.csrf?.mode === 'ajax';
        },

        addToCart(normalizedVariant, quantity = 1) {
            const formClass = `fl-add-to-cart-${normalizedVariant.id}`;

            const form = document.createElement('form');
            form.action = '/checkout/line-item/add';
            form.method = 'POST';
            form.dataset.addToCart = 'true';
            form.classList.add(formClass);

            FlUtils.appendAllHiddenFieldsToForm(form, {
                redirectTo: 'frontend.cart.offcanvas',
                redirectParameters: JSON.stringify({ productId: normalizedVariant.id }),
                [`lineItems[${normalizedVariant.id}][id]`]: normalizedVariant.id,
                [`lineItems[${normalizedVariant.id}][type]`]: 'product',
                [`lineItems[${normalizedVariant.id}][referencedId]`]: normalizedVariant.id,
                [`lineItems[${normalizedVariant.id}][quantity]`]: quantity,
            });

            document.body.appendChild(form);
            window.PluginManager.initializePlugin('AddToCart', { el: form });
            window.PluginManager.getPluginInstanceFromElement(form, 'AddToCart')._formSubmit(new Event('submit'));
            form.remove();
        },
    };
})();

FlUtils.extend(Shopware6, BaseShopSystem);
export default Shopware6;
