import config from '../config';
import jQuery from '../utils/fl-jquery';
import ShopSystem from './shopsystem/shopsystem';
import translations from '../translations';
import layoutAssistedSuggestHooks from '../../tscoba/src/LayoutAssistedSuggestHooks/LayoutAssistedSuggestHooks.ts';

window.flLayoutAssistedSuggestHooks = layoutAssistedSuggestHooks;

const DiContext = {
    fakeFlRequire(modules, callback) {
        // No benefit in splitting this up.
        // eslint-disable-next-line complexity
        const resolveModule = (module) => {
            switch (module) {
                case 'directIntegration':
                    return import('../modules/direct-integration').then((m) => m.default);
                case 'error-tracker':
                    return import('../modules/tracking/errors/error-tracker').then((m) => m.default);
                case 'flAutocomplete':
                    return import('./findologic-ac-2.0').then((m) => m.default);
                case 'utils/utils':
                case 'flUtils':
                    return import('../utils/utils').then((m) => m.default);
                case 'frontend':
                    return import('./frontend').then((m) => m.default);
                case 'modules/mobile-smart-suggest':
                    return import('./mobileSmartSuggest/mobile-smart-suggest').then((m) => m.default);
                case 'modules/request':
                    return import('./request').then((m) => m.default);
                case 'modules/tracking/analytics/tracker':
                    return import('@findologic/js-common').then((m) => m.analyticsTracker);
                case 'svgInjector':
                case 'utils/svg-injector':
                    return import('../utils/svg-injector').then((m) => m.default);
                case 'wizard':
                    return import('./wizard').then((m) => m.default);
                case 'meta':
                    return import('../meta').then((m) => m.default);
                default:
                    return new Promise((resolve) => resolve(resolveInstant(module)));
            }
        };

        const resolveInstant = (module) => {
            switch (module) {
                case 'jquery':
                case 'jquery-nc':
                    return jQuery;
                case 'shopSystem':
                    return ShopSystem.forConfig(config);
                case 'config':
                    return config;
                case 'translations':
                    return translations;
                case 'LayoutAssistedSuggestHooks':
                    return window.flLayoutAssistedSuggestHooks;
                default:
                    throw new Error(`Direct integration requested ${module}, but it was not found`);
            }
        };

        if (Array.isArray(modules)) {
            return Promise.all(modules.map(resolveModule)).then((m) => callback(...m));
        } else if (typeof callback === 'function') {
            return resolveModule(modules).then(callback);
        } else {
            return resolveInstant(modules);
        }
    },
};

const LegacyDirectIntegrationCallbackSupport = () => {
    window.flRequire = DiContext.fakeFlRequire;
    window.flDefine = () => {
        // eslint-disable-next-line no-console
        console.trace('flDefine is deprecated');
    };
    window.flRequireObject = {
        require: window.flRequire,
        define: window.flDefine,
    };
};

export default LegacyDirectIntegrationCallbackSupport;
