/*
 * Module to handle UI initialization in the search frontend. This replaces the templates for several inline scripts,
 * which still exist for now, so clients without autocomplete can still use the functionality.
 *
 * This module needs jQuery UI for some things, so we extend the jQuery object provided by jquery-nc with the jquery-ui
 * module by requiring it. Adding the jQuery UI plugin affects the instance created by jquery-nc, so we only need the
 * latter module in the actual implementation here.
 */

import config from '../config';
import jQuery from '../utils/fl-jquery';
import SVGInjector from '../utils/svg-injector';
import { logger, url as urlUtils } from '@findologic/js-common';

// The object to be returned as the module
const frontend = {
    frontendConfig: undefined,
    cdnBaseUrl: undefined,
};

/**
 * Replace click handlers of multiselect checkboxes.
 */
frontend.initializeMultiselects = function () {
    jQuery('input.flMultiselectCheckbox').click(function () {
        jQuery(jQuery(this).next('a')[0]).click();
    });
};

/**
 * Initialize range slider based on the data-* attributes provided in the template. Optionally, an element may be
 * provided to limit initialization to sliders below that element.
 *
 * In HTML_3.1 shops, the "filter" button is hidden by default. In that case, this method installs event
 * listeners to toggle its visibility depending on whether the input fields are focused.
 */
frontend.initializeRangeSliders = function (sliderGroup) {
    import('../utils/fl-jquery-ui-module').then(() => {
        let sliders;
        if (sliderGroup) {
            sliders = jQuery(sliderGroup).find('.findologic-slider');
        } else {
            sliders = jQuery('.findologic-slider');
        }

        sliders.each(function () {
            const filterElement = jQuery(this);

            const chosenMin = filterElement.data('chosenMin');
            const chosenMax = filterElement.data('chosenMax');
            const min = filterElement.data('min');
            const max = filterElement.data('max');
            const step = filterElement.data('step');

            const inputMinElement = filterElement.parent().find('.flSelectedMin input');
            const inputMaxElement = filterElement.parent().find('.flSelectedMax input');
            const formElement = filterElement.parent();

            if (frontend.frontendConfig.frontendType === 'HTML_3.1') {
                const filterButton = filterElement.parent().find('.fl-range-slider-button');

                // Toggle visibility of the filter button if input elements are focused.
                jQuery.each([filterButton.find('input'), inputMinElement, inputMaxElement], function () {
                    this.focus(function () {
                        filterButton.stop().slideDown(function () {
                            filterButton.css('height', 'auto');
                        });
                    });
                    this.blur(function () {
                        filterButton.stop().slideUp(function () {
                            filterButton.css('height', 'auto');
                        });
                    });
                });

                jQuery.each([inputMinElement, inputMaxElement], function () {
                    this.click(function () {
                        jQuery(this).select();
                    });
                });
            }

            inputMinElement.val(chosenMin);
            inputMaxElement.val(chosenMax);

            // Clear previous initializations to avoid multiple form submissions.
            try {
                filterElement.slider('destroy');
            } catch (e) {
                // The slider wasn't initialized yet. All is fine.
            }

            filterElement.slider({
                range: true,
                min,
                max,
                values: [chosenMin, chosenMax],
                step,
                slide(event, ui) {
                    inputMinElement.val(ui.values[0]);
                    inputMaxElement.val(ui.values[1]);
                },
                change() {
                    formElement.submit();
                },
            });
        });
    });
};

/**
 * Auto-select first option in a filter box.
 */
frontend.selectFirstFilterOption = function () {
    jQuery('.flFilterBox select option:first-child').attr('selected', 'selected');
};

/**
 * Adds a cache buster query parameter to an URL.
 *
 * @param {string} url The URL of which the cache should be busted.
 * @returns {string} The URL after modification.
 */
frontend.bustCache = function (url) {
    const urlParts = url.split('?');
    let parsedQueryParams;
    let prefix;

    if (urlParts.length > 1) {
        parsedQueryParams = urlUtils.parseQueryString(urlParts[urlParts.length - 1]);
        prefix = urlParts.slice(0, urlParts.length - 1);
    } else {
        // No existing query parameters.
        parsedQueryParams = {};
        prefix = urlParts[0];
    }

    parsedQueryParams['_'] = config.cacheBuster;

    return `${prefix}?${jQuery.param(parsedQueryParams)}`;
};

/**
 * Loads a CSS file once using a link tag. Repeated loading is prevented by checking if an element with the
 * given class name already exists.
 *
 * @param {string} url URL to the CSS file. Should be either https or protocol-agnostic. A cache buster will be added
 * according to configuration to allow on-demand reloads.
 * @param {string} className Class used to prevent repeated loading of the same CSS file. Should be prefixed with 'fl-'.
 */
frontend.loadCss = function (url, className) {
    url = frontend.bustCache(url);

    const existingLinkTag = jQuery(`.${className}`);

    if (existingLinkTag.length < 1) {
        logger.info(`Loading CSS with selector .${className} and URL ${url}.`);

        jQuery(jQuery.find('head')).append(
            jQuery('<link>', {
                rel: 'stylesheet',
                type: 'text/css',
                class: className,
                href: url,
            })
        );
    } else {
        const existingCssUrl = existingLinkTag.attr('href');
        logger.info(
            `CSS with selector .${className} has already been loaded with URL ${existingCssUrl}. CSS with URL ${url} will not be loaded.`
        );
    }
};

/**
 * Loads the CSS for jQuery UI by inserting a style tag.
 */
frontend.loadJQueryUiCss = function () {
    const jQueryUiCssUrl = `${frontend.cdnBaseUrl}/js/shop-js/assets/jquery-ui-themes-1.11.1/themes/smoothness/jquery-ui.min.css`;
    frontend.loadCss(jQueryUiCssUrl, 'fl-jquery-ui-css');
};

/**
 * Installs event handlers for interaction with the V3 template.
 *
 * @param {jQuery} [container] The element containing the filter widgets to initialize.
 */
frontend.initializeTemplateV3 = function (container) {
    if (typeof container === 'undefined') {
        container = jQuery('html');
    }

    // Different frontend versions use different classes for active accordion-type things.
    let activeClass;
    switch (frontend.frontendConfig.frontendType) {
        case 'HTML_3.0':
            activeClass = 'active';
            break;
        case 'HTML_3.1':
        default:
            activeClass = 'fl-active';
    }

    // Load FontAwesome, if it's not available already, but only if configured.
    if (config.frontendConfig.iconSet === 'font_awesome_4') {
        const fontAwesomeCssUrl = `${frontend.cdnBaseUrl}/js/autocomplete/assets/Font-Awesome-4.3.0/css/font-awesome.min.css`;
        frontend.loadCss(fontAwesomeCssUrl, 'fl-font-awesome');
    }

    // Toggle filter button for mobile view.
    container
        .find('.fl-show-filter-button')
        .off('click')
        .on('click', function () {
            jQuery(this).toggleClass(activeClass);
            const filters = jQuery('.fl-filter-wrapper');
            filters.stop().slideToggle();
            // Required for Html3.0-Template
            filters.removeClass('show-for-large-up');
            // Required for Html3.1-Template
            filters.removeClass('fl-show-for-medium-up');

            // Prevent skipping to the anchor of the clicked link and hash pollution.
            return false;
        });

    // Custom animated accordion.
    const accordion = container.find('.fl-filter-box-wrapper > a.fl-filter-name');
    accordion.off('click').on('click', function () {
        const accordionContainer = jQuery(this).parents('.fl-filter-box-wrapper');
        accordionContainer.toggleClass(activeClass);
        accordionContainer.find('.content, .fl-content').stop().slideToggle();

        // Prevent skipping to the anchor of the clicked link and hash pollution.
        return false;
    });

    // Expandable select list filter values.
    container
        .find('.fl-filter-type-select .fl-expand-select-values > a')
        .off('click')
        .on('click', function () {
            const filterBox = jQuery(this).parents('.fl-filter-box');
            const expandButton = filterBox.find('.fl-expand-select-values');
            const collapsedList = filterBox.children('.fl-select-filter').find('.fl-filter-extra');

            filterBox.css('height', 'auto');

            collapsedList.stop().slideToggle(400, function () {
                expandButton.toggleClass(activeClass);
            });

            // Prevent skipping to the anchor of the clicked link and hash pollution.
            return false;
        });

    // Replace whatever vector graphic img tags we have (tagged with a certain class) with the SVG markup. The
    // SVGInjector takes care of inserting a fallback automatically as specified in the data-fallback attribute
    // on the relevant img tag.
    SVGInjector(jQuery('.fl-svg'));
};

/**
 * Loads jQuery UI CSS and initializes all jQuery UI form elements (range slider and multiselect).
 *
 * @param {{frontendType: string, useJQueryUiCss: boolean}} frontendConfig The frontend configuration object.
 * @param {string} cdnBaseUrl Base URL of files hosted on our CDN.
 */
frontend.initializeFrontend = function (frontendConfig, cdnBaseUrl) {
    frontend.frontendConfig = frontendConfig;
    frontend.cdnBaseUrl = cdnBaseUrl;

    // Only load the jQuery UI CSS if the shop doesn't provide its own.
    if (frontendConfig.useJQueryUiCss) {
        frontend.loadJQueryUiCss();
    }
    frontend.initializeMultiselects();
    frontend.selectFirstFilterOption();
    // Initialization JS for HTML_3.x shops.
    if (typeof frontendConfig.frontendType !== 'undefined' && frontendConfig.frontendType.indexOf('HTML_3.') === 0) {
        frontend.initializeTemplateV3();
    }
};

export default frontend;
