import FlUtils from '../../utils/utils';
import BaseShopSystem from './base';

const XtCommerce = (function () {
    /* Public */
    return {
        NAME: 'XtCommerce',
        VERSION: 1,

        IDENTIFIER_PARAM: 'identifier',
        CAT_PARAM: 'attrib[cat][0]',
        VENDOR_PARAM: 'attrib[vendor][0]',

        /**
         * Appends shopsystem specific input fields to the form
         *
         * @param {JSON} item
         * @param {Object} form
         */
        appendSpecificItemsToForm(item, form) {
            // PRODUCT
            if (item.hasOwnProperty('identifier') && item.block === this.BLOCKS.PRODUCT) {
                this.appendInput(form, this.IDENTIFIER_PARAM, item.identifier);
            }

            // CAT
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.CAT) {
                this.appendInput(form, this.CAT_PARAM, item.label);
            }

            // Vendor
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.VENDOR) {
                this.appendInput(form, this.VENDOR_PARAM, item.label);
            }

            return this;
        },
    };
})();

FlUtils.extend(XtCommerce, BaseShopSystem);
export default XtCommerce;
