import jQuery from '../../utils/fl-jquery';
import constants from '../../constants';
import { NormalizedVariant } from '../../../tscoba/src/Common/NormalizedVariant.ts';
import Utils from '../../../tscoba/src/Common/Utils.ts';
import globalShopConfig from '../../config';

/**
 * Base object for shopSystem module objects
 */
const BaseShopSystem = (function () {
    /* Public */
    return {
        searchFieldElement: null,
        requireJsTextPluginPath: 'utils/text!',

        // All cat block items underscores will be replaced by the catDivider
        catDivider: '&nbsp;&rarr; ',

        /* All child objects should have the following properties */
        requiredProperties: ['NAME', 'VERSION'],

        BLOCKS: {
            CAT: 'cat',
            VENDOR: 'vendor',
            PRODUCT: 'product',
            SUGGEST: 'suggest',
            CONTENT: 'content',
            PROMOTION: 'promotion',
            LANDING_PAGE: 'landingpage',
            TOP_QUERIES: 'topQueries',
        },

        TYPES: {
            result: [constants.SMART_SUGGEST_TYPE_RESULT_V3],
        },

        // Class names
        flAcWrapperClassName: 'fl-autocomplete',
        flTwoColumnLayoutWrapperClassName: 'fl-has-two-columns',
        flUseTwoColumnsLayoutClassName: 'fl-use-two-columns-layout',
        flAcHighlightClassName: 'fl-ac-highlight',
        popOverClassName: 'fl-popOverImage',
        imageColDivClassName: 'image',
        itemImageColClassName: 'itemImage',

        imageReplaceWrapperClassName: 'imageReplaceWrapper',
        imageLoadingSpinnerClassName: 'imageLoadingSpinner',
        imageNotFoundClass: 'imageNotFound',

        filterFormFieldClassName: 'flFilterFormField',

        /* Define ids of the html templates */
        resultBlockTemplateId: 'fl-result-block-template',
        resultItemTemplateId: 'fl-result-item-template',
        resultShowAllResultId: 'fl-result-showAllResults-template',
        resultPromotionItemId: 'fl-result-item-promotion-template',
        resultLandingPageItemId: 'fl-result-item-landingPage-template',
        resultCatItemId: 'fl-result-cat-template',

        setSearchFieldElement(inputElement) {
            this.searchFieldElement = inputElement instanceof jQuery ? inputElement : jQuery(inputElement);
        },

        getFormFromInput(inputElement) {
            return jQuery(inputElement.form);
        },

        /**
         * Reset, called when autocomplete closes
         */
        reset() {
            this.hidePopOver();
            this.unbindEvents();
            this.removeSpecificItemsFromForm();
        },

        /**
         * Submits the form
         *
         * @param {Object} form
         */
        submitForm(form) {
            if (form.length === 1) {
                form.submit();
            } else {
                throw new Error("Form-submit isn't possible! There is more or less than one form-object available.");
            }
        },

        /**
         * Checks if the type result_v3.
         *
         * @param {string} type The type of the current item.
         * @returns {boolean} Returns true, if the item is result_v3, false otherwise.
         */
        isResultV3(type) {
            return type === constants.SMART_SUGGEST_TYPE_RESULT_V3;
        },

        /**
         * Triggers when a result li-element in the autocomplete was clicked
         *
         * @param {JSON} item
         * @param {Object} form
         * @param {String} type
         */
        appendItemsAndSubmitForm(item, form, type) {
            /**
             * When doing result autocomplete, the selected value is saved in a hidden form field and sent
             * to the server on submission, EXCEPT when dealing with suggestions: The jQuery autocomplete
             * plugin automatically pastes the suggestion text into the search box, so using a hidden field
             * is not necessary.
             *
             * In any case. The form needs to be submitted in the end, regardless of whether hidden fields
             * were used or not.
             *
             * This peculiarity is tested in gambioSpec.js.
             */
            if (this.isResultV3(type) && item.hasOwnProperty('block') && item.block !== 'suggest') {
                this.appendSpecificItemsToForm(item, form);
                this.clearSearchFieldElement();
            } else {
                this.searchFieldElement.val(item.label);
            }

            this.submitForm(form);
        },

        /**
         * Fix the form action
         *
         * Some shopsystems (Shopware, Veyton) lose some filters when redirecting the search form, so fix the
         * form action to point directly to the redirect target
         *
         * Ex.: Veyton has a form action of `/de/search`, the actual target of the search is `/de/vt_findologic`
         *
         * If such a fix is required the shopsystem module should overwrite this method
         *
         * @param {String} formAction The value of the action attribute of the form
         * @return {String} The fixed form action attribute value, may just be the same as the input if no fix is required
         */
        fixFormAction(formAction) {
            return formAction;
        },

        /**
         * Redirect to url
         *
         * @param {String} url
         */
        followUrl(url) {
            location.href = url;
        },

        /**
         * BindEvents after jQuery-ui has rendered the result
         */
        bindEvents() {
            this.bindImageHoverEvent();
            this.bindShowImageLoadingSpinner();
        },

        /**
         * Unbind events
         */
        unbindEvents() {
            this.unbindImageHoverEvent();
        },

        /**
         * Bind image onload event for every .itemImage
         */
        bindShowImageLoadingSpinner() {
            const imageColWrapper = jQuery(`.${this.flAcWrapperClassName}`).find(`.${this.imageColDivClassName}`);
            const self = this;

            imageColWrapper.each(function () {
                const currentWrapper = jQuery(this);
                self.showImageLoadingSpinner.call(self, currentWrapper);
            });
        },

        /**
         * Shows loading spinner until item image is loaded
         *
         * @param {jQuery} imageWrapper - The wrapper div where the image is in
         */
        showImageLoadingSpinner(imageWrapper) {
            const self = this;
            const image = imageWrapper.find(`.${this.itemImageColClassName}`);

            /* Add loading spinner until the image is loaded */
            this.showImageReplaceDiv(imageWrapper, this.imageLoadingSpinnerClassName);

            // Remove the loading spinner, as soon as the image is loaded
            image[0].onload = function () {
                self.removeImageReplaceDiv.call(self, imageWrapper);
                image.css({ visibility: 'visible' });
            };

            /*
          Handle image not found error
          => Show a image-not-found image
      */
            image[0].onerror = function () {
                // Make sure loading spinner gets removed
                self.removeImageReplaceDiv.call(self, imageWrapper);

                self.showImageReplaceDiv(imageWrapper, self.imageNotFoundClass);
            };
        },

        /**
         * <div> Overlays the product image. (E.g. loading spinner or no-image-found)
         *
         * @param {jQuery} imageWrapper - The wrapper div where the image is in
         * @param {String} cssStateClass The css class the wrapper should have too
         */
        showImageReplaceDiv(imageWrapper, cssStateClass) {
            const replaceWrapper = jQuery(`<div class="${this.imageReplaceWrapperClassName}"></div>`);
            replaceWrapper.addClass(cssStateClass);

            const image = imageWrapper.find(`.${this.itemImageColClassName}`);
            image.css({ visibility: 'hidden' });

            imageWrapper.append(replaceWrapper);
        },

        /**
         * Hides image replace div
         *
         * @param {jQuery} imageWrapper - The wrapper div where the image is in
         */
        removeImageReplaceDiv(imageWrapper) {
            imageWrapper.find(`.${this.imageReplaceWrapperClassName}`).remove();
        },

        /**
         * Bind event handler to the images
         */
        bindImageHoverEvent() {
            /*
          Each rendering of the items triggers this method
          To avoid multiple bindings, we first detach the event from the image columns
      */
            this.unbindImageHoverEvent();

            const imageColumns = jQuery(`.${this.flAcWrapperClassName}`);
            const self = this;
            const imageColSelector = `.${this.imageColDivClassName}`;

            // Mouseenter
            imageColumns.on('mouseenter', imageColSelector, function () {
                const element = jQuery(this);

                // FIX for IE: sometimes does not close the wrapper on mouseout event
                self.hidePopOver.call(self);

                // We only show a popup image if the image is not our image-not-found placeholder image
                if (!element.find(`.${self.imageNotFoundClass}`).length) {
                    self.showPopOver.call(self, element);
                }
            });

            // Mouseout
            imageColumns.on('mouseout', imageColSelector, function () {
                self.hidePopOver.call(self);
            });
        },

        /**
         * Unbind image hover event
         */
        unbindImageHoverEvent() {
            const imageColumns = jQuery(`.${this.flAcWrapperClassName}`);
            const imageColSelector = `.${this.imageColDivClassName}`;

            imageColumns.off('mouseenter', imageColSelector);
            imageColumns.off('mouseout', imageColSelector);
        },

        /**
         * Shows the product image in a popup
         *
         * @param {jQuery} imageWrapper The .image column div where the mouseover event was triggered
         */
        showPopOver(imageWrapper) {
            let popOverWrapper = jQuery(`.${this.popOverClassName}`);
            const thumbImage = imageWrapper.find('img');

            /*
          Only create the popOverWrapper div when it is not already in the DOM
      */
            if (!popOverWrapper.length) {
                popOverWrapper = jQuery('<div><img></div>');
                popOverWrapper.appendTo('body').addClass(this.popOverClassName);
            }

            /*
          The CSS decides the size of the popOver image (by max-width/height)
          Therefore we must show the popOverWrapper div and insert the image
          to be able to read the actual size of the popOver image.
      */
            const popOverImage = popOverWrapper.find('img');
            popOverImage.attr('src', thumbImage.attr('src'));
            popOverWrapper.show();
            const originalWidth = popOverImage.width();
            const originalHeight = popOverImage.height();
            // Hide it, cause we are not done yet
            popOverWrapper.hide();

            /*
          Now that we have got the rendered size, we can set the correct position of the popOverWrapper
          (centralized over the thumbImage)
      */
            const thumbCenterPosX = thumbImage.offset().left + thumbImage.width() / 2;
            const thumbCenterPosY = thumbImage.offset().top + thumbImage.height() / 2;
            let x = thumbCenterPosX - originalWidth / 2;
            let y = thumbCenterPosY - originalHeight / 2;

            /*
          But wait there is more! :D
          It may be that the popover image is cut by the browsers window
          Therefore we check if the popover image is not displayed entirely and correct its position
      */

            const marginTop = 10;
            const marginLeft = 10;

            // Out of screen-y correction
            const windowBottomY = jQuery(window)[0].innerHeight + window.scrollY;
            if (y + originalHeight > windowBottomY) {
                y = thumbCenterPosY - originalHeight + (windowBottomY - thumbCenterPosY) - marginTop;
            }

            // Out of screen-x correction
            if (x < 0) {
                x = marginLeft;
            }

            popOverWrapper
                .css({
                    width: originalWidth,
                    height: originalHeight,
                    left: x,
                    top: y,
                })
                .fadeIn(); // Tada!
        },

        /**
         * Hides the image pop-over
         */
        hidePopOver() {
            const popOverWrapper = jQuery(`.${this.popOverClassName}`);
            if (popOverWrapper.is(':visible')) {
                popOverWrapper.hide();
            } else {
                popOverWrapper.stop(true);
            }
        },

        /**
         * Appends hidden input to the form element
         *
         * @param {Object} form The form where the input is appended
         * @param {String} name The name of the input field
         * @param {String} value The value of the input field
         */
        appendInput(form, name, value) {
            const input = jQuery('<input>');
            input.attr('type', 'hidden').attr('name', name).addClass(this.filterFormFieldClassName).val(value);

            form.append(input);

            return this;
        },

        /**
         * This method clears the value of the search field element
         *
         * In some cases if the user performs a search request by clicking on an item,
         * then we don't want to send a search-field value.
         */
        clearSearchFieldElement() {
            this.searchFieldElement.val('');
        },

        /**
         * Appends additional and shop specific to form
         *
         * @param {JSON} item
         * @param {Object} form
         */
        // eslint-disable-next-line no-unused-vars
        appendSpecificItemsToForm(item, form) {
            // overwrite me
        },

        /**
         * Removes hidden inputs which were previously appended to the form
         */
        removeSpecificItemsFromForm() {
            jQuery(`.${this.filterFormFieldClassName}`).remove();
        },

        /**
         * Hook for fixing the URL of a product before it is followed after the product was clicked in the
         * Smart Suggest product suggestions. Leaves the URL unchanged by default.
         *
         * @param {{}} shopConfig Shop configuration to allow for configuration-sensitive URL manipulation.
         * @param {String} url The URL to fix.
         * @return {String} The processed URL; same as the parameter url by default.
         */
        fixProductUrl(shopConfig, url) {
            return url;
        },

        /**
         * Hook for modifying the query parameters of a Smart Suggest request before it is sent. As a
         * general rule of thumb, never transmit less than before in order to maintain forward
         * compatibility. By default, the original parameters are passed along as-is.
         *
         * parameters contains all search form values, plus those specified below, which are standardized.
         *
         * @param {{}} parameters The parameters of the original request.
         * @param {string} parameters.q The query term.
         * @param {string} parameters.query The query term.
         * @param {string} parameters.type The variety of Smart Suggest being used in this specific shop.
         * @param {number} parameters.timestamp The time at which the query occurred, acting as a cache buster.
         * @params {[]} [parameters.autocompleteblocks] The suggestion blocks to show, if structured.
         * @params {number} [parameters.count] Number of suggestions to show per block, if structured.
         * @params {string} [parameters.usergrouphash] The obfuscated string that identifies users, in case of
         * Direct Integration.
         * @params {string} [parameters.shopkey] The shop's shopkey, in case no Smart Suggest proxy is used.
         * @return {{}} Modified parameters for the Smart Suggest request.
         */
        transformSmartSuggestRequest(parameters) {
            return parameters;
        },

        /**
         * Disables specific touch events on Smart Suggest to prevent clicks from propagating to what's
         * below the suggestions.
         *
         * Happened originally in Shopware, but generally useful. See #119264181.
         */
        stopTouchEventsOnSmartSuggestFromPropagation() {
            function touchStopper(e) {
                // Since preventing touch events would also prevent clicks, which are necessary for Smart
                // Suggest to function, we need to trigger them manually.
                jQuery(e.target).click();

                return false;
            }

            jQuery.each(['touchstart', 'touchend'], function (i, event) {
                // Although .fl-suggestion-icon is withing .fl-autocomplete, it and its children need to be
                // treated specially, presumably due to the nature of embedded SVG.
                jQuery.each(['.fl-autocomplete', '.fl-suggestion-icon *'], function (j, selector) {
                    jQuery(document).on(event, selector, touchStopper);
                });
            });
        },

        /**
         * Called once Smart Suggest has been initialized. May be used to validate the search form or fix
         * shop system-specific workarounds.
         */
        init() {
            this.stopTouchEventsOnSmartSuggestFromPropagation();
        },

        beforeDIHashchange() {},

        afterDIHashchange() {},

        afterDISubmitEventRegistration() {},

        beforeOperation(operation, params) {
            return params;
        },

        /**
         * @param item {BaseItem}
         * @param imageProperties {string[]}
         * @return {NormalizedVariant[]}
         */
        // eslint-disable-next-line no-unused-vars
        getNormalizedVariants(item, imageProperties) {
            // If no ordernumber is available, variant information doesn't make sense, as it can't be used for all carts.
            if (item.ordernumbers.length < 1) {
                return [];
            }

            if (!item.variants || item.variants.length === 0) {
                return [new NormalizedVariant(item.id, item.ordernumbers[0], 0, Utils.getAllItemImages(item, imageProperties))];
            }

            return item.variants.map(
                (variant) =>
                    new NormalizedVariant(
                        variant.id,
                        variant.ordernumbers[0] || null,
                        null,
                        Utils.getAllItemImages(variant, imageProperties),
                        variant.url,
                        variant?.attributes,
                        globalShopConfig.instantFrontend.productListingPage.productCard.variantAttributes
                    )
            );
        },

        supportsAddToCart: false,

        /**
         * @param normalizedVariant NormalizedVariant The variant to add to the cart.
         * @param quantity The amount of products to be added to the card
         * @return Promise<Response> | never The response of the request, or never in case of a redirect.
         * @throws Error If there is no implementation.
         */
        // eslint-disable-next-line no-unused-vars
        addToCart(normalizedVariant, quantity = 1) {
            throw new Error('Not implemented');
        },
    };
})();

export default BaseShopSystem;
