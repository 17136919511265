import BigCommerce from './bigcommerce';
import Cosmoshop from './cosmoshop';
import DirectIntegration from './directIntegration';
import EVendo from './e_vendo';
import eigenesSonstiges from './eigenes_sonstiges';
import Gambio from './gambio';
import JTL from './jtl';
import Magento from './magento';
import Modified from './modified';
import OsCommerce from './oscommerce';
import Oxid from './oxid';
import Plentymarkets from './plentymarkets';
import Shopify from './shopify';
import Shopware from './shopware';
import Shopware6 from './shopware_6';
import ShopwareApp from './shopware_app';
import Websale from './websale';
import Xanario from './xanario';
import XtcVeyton from './xtc_veyton';
import XtCommerce from './xtcommerce';

const ShopSystem = {
    // There is no point in splitting this up.
    // eslint-disable-next-line complexity
    forConfig(config, skipDirectIntegrationCheck = false) {
        if (!skipDirectIntegrationCheck && config.directIntegration && config.directIntegration.enabled) {
            return DirectIntegration;
        }

        switch (config.shopSystem?.toLowerCase()) {
            case 'bigcommerce':
                return BigCommerce;
            case 'cosmoshop':
                return Cosmoshop;
            case 'directintegration':
                return DirectIntegration;
            case 'e_vendo':
                return EVendo;
            case 'eigenes_sonstiges':
                return eigenesSonstiges;
            case 'gambio':
                return Gambio;
            case 'jtl':
                return JTL;
            case 'magento':
                return Magento;
            case 'modified':
                return Modified;
            case 'oscommerce':
                return OsCommerce;
            case 'oxid':
                return Oxid;
            case 'plentymarkets':
                return Plentymarkets;
            case 'shopify':
                return Shopify;
            case 'shopware':
                return Shopware;
            case 'shopware_6':
                return Shopware6;
            case 'shopware_app':
                return ShopwareApp;
            case 'websale':
                return Websale;
            case 'xanario':
                return Xanario;
            case 'xtc_veyton':
                return XtcVeyton;
            case 'xtcommerce':
                return XtCommerce;
        }
    },
};

export default ShopSystem;
