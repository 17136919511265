import FlUtils from '../../utils/utils';
import BaseShopSystem from './base';

const OXID = (function () {
    /* Public */
    return {
        NAME: 'OXID',
        VERSION: 1,

        /**
         * Appends shopsystem specific input fields to the form
         *
         * @param {JSON} item
         */
        appendSpecificItemsToForm(item, form) {
            // PRODUCT
            if (item.hasOwnProperty('identifier') && item.block === this.BLOCKS.PRODUCT) {
                this.appendInput(form, 'identifier', item.identifier);
            }

            // CAT
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.CAT) {
                this.appendInput(form, 'attrib[cat][]', item.label);
            }

            // Vendor
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.VENDOR) {
                this.appendInput(form, 'attrib[vendor][]', item.label);
            }

            return this;
        },
    };
})();

FlUtils.extend(OXID, BaseShopSystem);

export default OXID;
