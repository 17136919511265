import frontend from './modules/frontend';
import jQuery from './utils/fl-jquery';
import FlUtil from './utils/utils';
import constants from './constants';
import ShopSystem from './modules/shopsystem/shopsystem';
import LegacyDirectIntegrationCallbackSupport from './modules/legacy-direct-integration-callback-support';
import wizard from './modules/wizard';
import globalShopConfig from './config';
import globalShopConfigDefaults from './data/configDefaults.json';
import globalTranslations from './translations';
import AnalyticsTracker from '@findologic/js-common/src/tracking/analytics/interface';
import { localStorage, url } from '@findologic/js-common';
import { cloneDeep, merge } from 'lodash-es';

// eslint-disable-next-line no-unused-vars
let globalShopSystem = {};

const Findologic = {
    reinitializationTriggered: false,

    handleConfigOverrides(config) {
        const overrideParam = 'flConfigOverrides';
        const storageKey = 'findologic-config-overrides';
        const queryParams = url.parseQueryString(window.location.search);
        const possibleOverride = queryParams.hasOwnProperty(overrideParam) ? JSON.parse(queryParams[overrideParam]) : null;

        const injectedShoppingConfigParam = 'flInjectedShoppingConfig';
        const injectedShoppingConfig = queryParams.hasOwnProperty(injectedShoppingConfigParam)
            ? JSON.parse(queryParams[injectedShoppingConfigParam])
            : null;
        this.setInjectedShoppingGuideConfig(injectedShoppingConfig);

        const requestedShoppingGuideParam = 'flShoppingGuide';
        const requestedShoppingGuide = queryParams[requestedShoppingGuideParam] || null;
        this.setRequestedShoppingGuide(requestedShoppingGuide);

        this.applyConfigDefaults(config);
        this.clearConfigOverridesIfRequested(possibleOverride, storageKey);
        this.storeConfigOverridesIfDetectedAndClean(possibleOverride, storageKey);
        this.applyStoredConfigOverridesIfAvailable(config, overrideParam, storageKey);
    },

    applyConfigDefaults(config) {
        // Only apply defaults for Instant Frontend config stuff, because other configs don't have any pervasively
        // defined defaults, plus lots of potential test failures when using defaults that don't match the setup.
        //
        // Use lodash merge instead of jQuery.extend, because it only considers properties missing if they are
        // undefined, whereas jQuery.extend considers everything missing that's falsy. jQuery's behavior is
        // unacceptable, because it makes it impossible to configure falsy defaults for properties with a
        // truthy default.
        merge(config, { instantFrontend: globalShopConfigDefaults.instantFrontend }, cloneDeep(config));
    },

    setInjectedShoppingGuideConfig(config) {
        sessionStorage.setItem('injectedShoppingConfig', JSON.stringify(config));
    },

    setRequestedShoppingGuide(guideName) {
        sessionStorage.setItem('requestedShoppingGuide', guideName);
    },

    clearConfigOverridesIfRequested(possibleOverride, storageKey) {
        if (possibleOverride === false) {
            // eslint-disable-next-line no-console
            console.info('Findologic config overrides cleared.');
            sessionStorage.removeItem(storageKey);
        }
    },

    storeConfigOverridesIfDetectedAndClean(possibleOverride, storageKey) {
        if (possibleOverride) {
            if ('directIntegration' in possibleOverride && 'callbacks' in possibleOverride.directIntegration) {
                // eslint-disable-next-line no-console
                console.info('Findologic config overrides contain script injection - ignoring them.');
            } else {
                // eslint-disable-next-line no-console
                console.info('Findologic config overrides detected - storing them.');
                sessionStorage.setItem(storageKey, JSON.stringify(possibleOverride));
            }
        }
    },

    applyStoredConfigOverridesIfAvailable(config, overrideParam, storageKey) {
        if (sessionStorage.getItem(storageKey) !== null) {
            // eslint-disable-next-line no-console
            console.info('Findologic config overrides are in effect. ' + `Disable using parameter ?${overrideParam}=false`);
            const overrides = JSON.parse(sessionStorage.getItem(storageKey));
            jQuery.extend(true, config, overrides);
        }
    },

    requireDependenciesAndLaunch(config, translations) {
        LegacyDirectIntegrationCallbackSupport();

        this.handleConfigOverrides(config);

        Object.assign(globalShopConfig, config);
        Object.assign(globalTranslations, translations);
        globalShopSystem = ShopSystem.forConfig(config);
        window.jQueryFl = jQuery;
        globalShopConfig.searchFieldElement = FlUtil.getSearchFieldElements();

        this.loadCss();

        if (globalShopConfig.searchFieldElement.length) {
            this.deferredInitialization();
        } else {
            // The search field was not available yet. Wait until the DOM is ready, then, since we can't tell if
            // the search field is in the DOM before the ready event without polling the selector.
            jQuery(document).ready(() => {
                this.deferredInitialization();
            });
        }

        jQuery(window).on('pageshow', (evt) => {
            // We only want to reinitialize FINDOLOGIC if the page has been loaded from the back-forward cache.
            // So check the attribute `persisted` which is false on initial page load and true if the page was
            // loaded from the back-forward cache.
            if (evt.originalEvent.persisted) {
                this.initializeFrontend();
            }
        });
    },

    /**
     * Detects special query parameters, such as the environment override and writes their value to
     * local storage for later use.
     */
    detectQueryParams() {
        const envPattern = new RegExp(`fl-env=(${constants.ENV_PROD}|${constants.ENV_DEV}|${constants.ENV_PREVIEW})`);
        let environment = window.location.search.match(envPattern);
        if (environment !== null) {
            environment = environment[1];
            localStorage.setItem(constants.STORAGE_KEY_ENV, environment);
        }

        const previewBranch = window.location.search.match(/fl-preview-branch=(.*?)(?:&|$)/);
        if (environment === constants.ENV_PREVIEW && previewBranch !== null) {
            localStorage.setItem(constants.STORAGE_KEY_PREVIEW_BRANCH, previewBranch[1]);
        }
    },

    /**
     * Adds an event to the search form and checks continuously if it exists. If the event gets removed by third party
     * scripts, we re-initialize our scripts.
     * This should work with each framework (except they delete our event all the time and not once).
     */
    reInitializeFindologicIfEventsAreDeleted() {
        let searchForm;
        if (this.isEventOverridden()) {
            return false;
        }
        const customEventName = 'FINDOLOGICInputNotDeleted';
        const customEvent = new Event(customEventName, {});
        let eventIsMissing = true;

        // Always get a new copy of the search form and not a cached one from the config.
        try {
            searchForm = FlUtil.getSearchFieldElements().parents('form')[0];
            searchForm.addEventListener(customEventName, function () {
                eventIsMissing = false;
            });
        } catch (e) {
            // SearchForm is not an element
            return;
        }

        const waitInterval = setInterval(() => {
            eventIsMissing = true;
            searchForm = FlUtil.getSearchFieldElements().parents('form')[0];
            searchForm.dispatchEvent(customEvent);

            if (eventIsMissing) {
                // Since we do not want to be in a request loop, we just clear the interval if we see that our events
                // got removed.
                clearInterval(waitInterval);
                // Make sure to set the searchFieldElement, so everyone who uses it, will use the fresh one.
                globalShopConfig.searchFieldElement = FlUtil.getSearchFieldElements();
                this.reinitializationTriggered = true;
                this.initMSSAndDI();
            }
        }, constants.INIT_RETRY_INTERVAL_MS);

        setTimeout(function () {
            clearInterval(waitInterval);
        }, constants.INIT_RETRY_INTERVAL_MS * constants.MAX_INIT_RETRIES);
    },

    isEventOverridden() {
        return !RegExp('native code').test(window.Event.toString());
    },

    /**
     * @returns hadErrors
     */
    runInitCallback() {
        const ignoreInitCallbackFlag = 'ignoreInitCallback';
        if (window.location.href.includes(ignoreInitCallbackFlag)) {
            return;
        }

        /** additionalParams was used in the BOSS implementation, but was not initialized, which causes multiple
         * integrations to break. Due to the fact that this is currently still in production in multiple shops,
         * we decided to initialize this variable here.*/
        let additionalParams = {};
        // eslint-disable-next-line no-unused-vars
        additionalParams = this.markVariableAsUsed(additionalParams);
        // eslint-disable-next-line no-unused-vars,no-eval
        const initCallback = eval(`(${globalShopConfig.directIntegration.callbacks.init})`);
        initCallback();
    },

    /** Due to the smart cleanup, unused variables are getting removed. If we need variables which should be defined
     * in the callbacks, but are not used in the setup here, we need this helper function to mark those as used*/
    markVariableAsUsed(variable) {
        return variable;
    },

    deferredInitialization() {
        this.detectQueryParams();

        try {
            this.runInitCallback();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(`FINDOLOGIC is unavailable due to a faulty DI init callback: ${e}`);

            return; // Abort further script execution in order to avoid a broken shop.
        }

        AnalyticsTracker.getInstance(globalShopConfig, false).trackParameters(window.location.href);
        this.initMSSAndDI();
        this.reInitializeFindologicIfEventsAreDeleted();
        this.initializeWizard();
        this.initializeFrontend();
    },

    loadCss() {
        // Load autocomplete css files
        // If none is given, then the client may have its own one
        const cssFile = globalShopConfig.cssFile;
        if (cssFile) {
            if (cssFile instanceof Array) {
                cssFile.forEach((cssUrl) => {
                    frontend.loadCss(cssUrl, 'fl-smart-suggest-css');
                });
            } else {
                frontend.loadCss(cssFile, 'fl-smart-suggest-css');
            }
        } else {
            FlUtil.Logger.warn('No CSS file specified in FINDOLOGIC autocompleteConfig');
        }
    },

    initMSSAndDI() {
        if (FlUtil.isMobile(globalShopConfig) && globalShopConfig.mobileSmartSuggest.config.enabled) {
            this.initializeMobileSmartSuggest().then(() => this.initializeDirectIntegration());
        } else {
            switch (globalShopConfig.autocompleteLayout) {
                case 'Classic':
                    this.initializeClassicSuggest().then(() => this.initializeDirectIntegration());
                    break;
                case 'Assisted Suggest':
                    this.initializeAssistedSuggest().then(() => this.initializeDirectIntegration());
                    break;
                default:
                    this.initializeDirectIntegration();
                    break;
            }
        }
    },

    triggerReadyEvent() {
        window.findologicReady = true;
        if (this.isEventOverridden()) {
            const errorMsg = 'The Event "findologicReady" could not be triggered because window.Event was overridden';
            FlUtil.Logger.warn(errorMsg);

            return false;
        }

        document.dispatchEvent(new Event('findologicReady'));
    },

    initializeDirectIntegration() {
        const isShopWithDirectIntegration =
            (globalShopConfig.directIntegration && globalShopConfig.directIntegration.enabled) ||
            (globalShopConfig.instantFrontend?.productListingPage.enabled ?? false);
        if (!isShopWithDirectIntegration) {
            return;
        }

        import('./modules/direct-integration').then(({ default: directIntegration }) => {
            directIntegration.init(this.triggerReadyEvent.bind(this), this.reinitializationTriggered);
        });
    },

    initializeMobileSmartSuggest() {
        return import('./modules/mobileSmartSuggest/mobile-smart-suggest').then(({ default: MobileSmartSuggest }) => {
            new MobileSmartSuggest({ type: globalShopConfig.mobileSmartSuggest.config.apiVersion });
        });
    },

    initializeClassicSuggest() {
        return import('../src/modules/findologic-ac-2.0').then(({ default: FlAutocomplete }) => {
            FlAutocomplete.shopConfig = globalShopConfig;
            FlAutocomplete.log = FlUtil.Logger;
            FlAutocomplete.init(
                globalShopConfig,
                globalShopConfig.directIntegration && globalShopConfig.directIntegration.enabled,
                () => {}
            );
        });
    },

    initializeAssistedSuggest() {
        return import('../tscoba/src/LayoutAssistedSuggest/LayoutAssistedSuggest.ts').then(({ default: LayoutAssistedSuggestBundle }) => {
            LayoutAssistedSuggestBundle.init();
        });
    },

    initializeWizard() {
        wizard.init();
    },

    initializeFrontend() {
        if (globalShopConfig.directIntegration.enabled) {
            frontend.initializeFrontend(globalShopConfig.frontendConfig, globalShopConfig.cdnBaseUrl);
            frontend.initializeRangeSliders('#flFilterContainer');
            frontend.initializeRangeSliders('.fl-filter-container');
        }
    },
};

export default Findologic;
