import FlUtils from '../../utils/utils';
import BaseShopSystem from './base';
import config from '../../config';
import jQuery from '../../utils/fl-jquery';
import { logger } from '@findologic/js-common';

const PlentyMarkets = (function () {
    /* Public */
    return {
        NAME: 'PlentyMarkets',
        VERSION: 1,

        /**
         * Appends shopsystem specific input fields to the form
         *
         * @param {JSON} item
         * @param {Object} form
         */
        appendSpecificItemsToForm(item, form) {
            // PRODUCT
            if (item.hasOwnProperty('identifier') && item.block === this.BLOCKS.PRODUCT) {
                this.appendInput(form, 'identifier', item.identifier);
            }

            // CAT
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.CAT) {
                this.appendInput(form, 'attrib[cat][]', item.label);
            }

            // Vendor
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.VENDOR) {
                this.appendInput(form, 'attrib[vendor][]', item.label);
            }

            return this;
        },

        /**
         * Hook for fixing the URL of a product before it is followed after the product was clicked in the
         * Smart Suggest product suggestions. For PlentyMarkets, the domain part is checked to make sure it
         * matches the configured domain. If the domains don't match, the configured one is used instead.
         *
         * @param {{shopUrl: String}} shopConfig Shop configuration to allow for configuration-sensitive URL manipulation.
         * @param {String} url The URL to fix.
         * @return {String} The processed URL. The domain is changed to the configured one, if not matching.
         */
        fixProductUrl(shopConfig, url) {
            // By setting the href of a temporary anchor tag, the domain becomes accessible as a property of
            // the element object. Changing the hostname property affects the href, so this is a handy way
            // to change the domain cleanly.
            const tempA = document.createElement('a');
            tempA.href = url;
            tempA.hostname = shopConfig.shopUrl;

            return tempA.href;
        },

        /**
         * Converts 'customer_class' to 'group' without removing the original for consistency with our Smart
         * Suggest API. This is only done for shops NOT using a Smart Suggest proxy.
         *
         * @param {{}} parameters The parameters of the original request.
         * @param {string} parameters.q The query term.
         * @param {string} parameters.query The query term.
         * @param {string} parameters.type The variety of Smart Suggest being used in this specific shop.
         * @param {number} parameters.timestamp The time at which the query occurred, acting as a cache buster.
         * @param {number} [parameters.customer_class] The identifier of the customer group, which is copied to the
         * 'group' parameter here.
         * @param {number[]} [parameters.group] Replacement for customer_class, if it exists.
         * @return {{}} Modified parameters for the Smart Suggest request.
         */
        transformSmartSuggestRequest(parameters) {
            if (config.useAutocompleteProxy === false) {
                if ('customer_class' in parameters) {
                    logger.info('Converting customer_class to group for Smart Suggest request.');
                    parameters.group = parameters.customer_class === 0 ? [2] : [1];
                }
            }

            return parameters;
        },

        /**
         * Ensures that customer_class and multishop_id are available as hidden fields in the search form,
         * as well as that they are numeric. Having multiple inputs with the same name also results in a
         * warning.
         *
         * If multiple matching search fields exist, they are checked individually.
         */
        init() {
            BaseShopSystem.init();

            function requireNumericHiddenField(form, formIndex, name) {
                const field = form.find(`input[name=${name}][type=hidden]`);
                if (field.length < 1) {
                    logger.warn(`No ${name} provided in search form number ${formIndex}`);
                } else if (!jQuery.isNumeric(field.val())) {
                    logger.warn(`${name} is not numeric in search form number ${formIndex}`);
                }

                if (field.length > 1) {
                    logger.warn(
                        `Multiple occurrences of ${name} in form number ${formIndex}. The first one with value ${field.val()} will be used.`
                    );
                }
            }

            const searchFields = FlUtils.getSearchFieldElements();
            searchFields.each(function (index, searchField) {
                const form = jQuery(searchField).parents('form');
                const formIndex = index + 1;

                requireNumericHiddenField(form, formIndex, 'multishop_id');
                requireNumericHiddenField(form, formIndex, 'customer_class');
            });
        },
    };
})();

FlUtils.extend(PlentyMarkets, BaseShopSystem);

export default PlentyMarkets;
