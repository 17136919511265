import FlUtils from '../../utils/utils';
import BaseShopSystem from './base';

const DirectIntegration = (function () {
    /* Public */
    return {
        NAME: 'DirectIntegration',
        VERSION: 1,

        IDENTIFIER_PARAM: 'identifier',
        CAT_PARAM: 'attrib[cat][0]',
        VENDOR_PARAM: 'attrib[vendor][0]',

        /**
         * @see base.js for doc
         */
        appendItemsAndSubmitForm(item, form, type) {
            if (this.isResultV3(type) && item.hasOwnProperty('block') && item.block !== 'suggest') {
                this.appendSpecificItemsToForm(item, form);
                this.clearSearchFieldElement();
            } else {
                this.searchFieldElement.val(item.label);
            }

            this.submitForm(form);
        },

        /**
         * Appends shopsystem specific input fields to the form
         *
         * @param {JSON} item
         * @param {Object} form
         */
        appendSpecificItemsToForm(item, form) {
            // PRODUCT
            if (item.hasOwnProperty('identifier') && item.block === this.BLOCKS.PRODUCT) {
                this.appendInput(form, this.IDENTIFIER_PARAM, item.identifier);
            }

            // CAT
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.CAT) {
                this.appendInput(form, this.CAT_PARAM, item.label);
            }

            // Vendor
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.VENDOR) {
                this.appendInput(form, this.VENDOR_PARAM, item.label);
            }

            return this;
        },
    };
})();

FlUtils.extend(DirectIntegration, BaseShopSystem);
export default DirectIntegration;
