import FlUtils from '../../utils/utils';
import BaseShopSystem from './base';
import { cloneDeep as _cloneDeep, merge as _merge } from 'lodash-es';
import { NormalizedVariant } from '../../../tscoba/src/Common/NormalizedVariant.ts';
import Utils from '../../../tscoba/src/Common/Utils.ts';

const Shopify = (function () {
    /**
     * This variable has the original Shopify History.ExtractState() method inside as a backup of
     * the original.
     *
     * @type {function}
     * @private
     */
    let shopifyExtractStateMethod = function () {};

    /**
     * The submitted navigation hash will just translate the leading "/" and will replace it with an
     * "#" and removes any "?" at the end of the string.
     *
     * @param {string} brokenNavigationHash
     * @return {string}
     * @private
     */
    const getFixedNavigationHash = function (brokenNavigationHash) {
        return brokenNavigationHash.replace(/^\//, '#').replace(/\?$/, '');
    };

    /**
     * This method will take the broken Shopify URL and fixes it to the FINDOLOGIC format.
     *
     * Broken URL: {domain}/{brokenCategoryPath}/navigation:attrib[cat_url][0]={catUrl}&{selectedFilters}?{queryParams}
     * Fixed URL: {domain}/{catUrl}?{queryParams}#navigation:attrib%5Bcat_url%5D%5B0%5D={catUrl}&{selectedFilters}
     *
     * @param {RegExpMatchArray} brokenNavigationHash Broken Shopify navigation hash e.g.
     * "/navigation:attrib[cat_url][0]={catUrl}&{selectedFilters}"
     * @param {string} queryParams All submitted query params e.g. ?findologic=on&blubbergurken=1
     * @return {string}
     * @private
     */
    const buildFixedNavigationUrl = function (brokenNavigationHash, queryParams) {
        const catUrlRegex = new RegExp(/attrib\[cat_url]\[0]=\/(.*?)&/);

        const catUrl = window.location.href.match(catUrlRegex)[1];
        const navigationHash = getFixedNavigationHash(brokenNavigationHash[0]);

        return `/${catUrl}${queryParams}${navigationHash}`;
    };

    /**
     * @param {object} directIntegration
     * @param {RegExp} regExp
     * @param {number} waitInterval
     * @param {function} callback Will only be called if the URL really was fixed.
     * @private
     */
    const checkUrlAndFixIfRequired = function (regExp, waitInterval) {
        const queryParams = window.location.search;
        const brokenNavigationHash = window.location.href.match(regExp);

        if (brokenNavigationHash) {
            // We only want to fix the URL once.
            clearInterval(waitInterval);
            Shopify.disableShopifyHistoryExtractState();

            const navigationUrl = buildFixedNavigationUrl(brokenNavigationHash, queryParams);

            window.history.replaceState({}, document.title, navigationUrl);
            Shopify.enableShopifyHistoryExtractState();

            return true;
        }

        return false;
    };

    /* Public */
    return {
        NAME: 'Shopify',
        VERSION: 1,

        SHOPIFY_BROKEN_NAVIGATION_URL_CHECK_INTERVAL: 500,
        SHOPIFY_BROKEN_NAVIGATION_URL_MAX_RETRIES: 10,

        /**
         * Appends shopsystem specific input fields to the form
         *
         * @param {JSON} item
         * @param {Object} form
         */
        appendSpecificItemsToForm(item, form) {
            // PRODUCT
            if (item.hasOwnProperty('identifier') && item.block === Shopify.BLOCKS.PRODUCT) {
                Shopify.appendInput(form, 'identifier', item.identifier);
            }

            // CAT
            if (item.hasOwnProperty('block') && item.block === Shopify.BLOCKS.CAT) {
                Shopify.appendInput(form, 'attrib[cat][]', item.label);
            }

            // Vendor
            if (item.hasOwnProperty('block') && item.block === Shopify.BLOCKS.VENDOR) {
                Shopify.appendInput(form, 'attrib[vendor][]', item.label);
            }

            return this;
        },

        /**
         * Shopify may destroy our own hash URLs. They map hashes to the URL and remove the entire hash.
         * Since FINDOLOGIC loads after the Shopify scripts, we need to manually detect these URLs and
         * get the full hash out of it, without causing a page reload or re-trigger the Shopify logic
         * that would destroy our URLs.
         * A destroyed navigation URL may look something like
         * {domain}/{brokenCategoryPath}/navigation:attrib[cat_url][0]={catUrl}&{selectedFilters}?{queryParams}
         *
         * @param {object} directIntegration
         * @param {function} callback Is only called if the URL was really fixed. If it was already fixed,
         * nothing will happen.
         */
        fixBrokenNavigationUrl(directIntegration) {
            // Check if there is a navigation:attrib[cat_url] in the URL itself. If there are any query
            // parameters at the end, we get everything from this string, excluding any set query
            // parameters.
            const isDestroyedNavigation = new RegExp(/\/navigation:.*\[cat_url].+\?|\/navigation:.*\[cat_url].+/);
            const waitInterval = setInterval(function () {
                const fixed = checkUrlAndFixIfRequired(isDestroyedNavigation, waitInterval);
                if (fixed && typeof directIntegration.init === 'function') {
                    const callback = directIntegration.init.bind(directIntegration);
                    callback();
                }
            }, Shopify.SHOPIFY_BROKEN_NAVIGATION_URL_CHECK_INTERVAL);

            setTimeout(function () {
                clearInterval(waitInterval);
            }, Shopify.SHOPIFY_BROKEN_NAVIGATION_URL_CHECK_INTERVAL * Shopify.SHOPIFY_BROKEN_NAVIGATION_URL_MAX_RETRIES);
        },

        disableShopifyHistoryExtractState() {
            if (typeof window.History.extractState === 'function') {
                shopifyExtractStateMethod = History.extractState;
            }

            // Temporally override this method so Shopify can no longer override our URLs. This function's
            // intentional purpose is to return the hash. If we just return nothing here, we should be fine
            // and Shopify can no longer override our hashes.
            window.History.extractState = function () {};
        },

        enableShopifyHistoryExtractState() {
            // Shopify may take longer to fix the URL, so we wait a small interval to prevent timing
            // issues with our fixed URL and Shopifys URL mapping.
            setTimeout(function () {
                window.History.extractState = shopifyExtractStateMethod;
            }, 500);
        },

        beforeDIHashchange() {
            this.disableShopifyHistoryExtractState();
        },

        afterDIHashchange() {
            this.enableShopifyHistoryExtractState();
        },

        registerOnPopState(directIntegration) {
            const self = this;
            window.onpopstate = function () {
                self.fixBrokenNavigationUrl(directIntegration);
            };
        },

        afterDISubmitEventRegistration(directIntegration) {
            this.fixBrokenNavigationUrl(directIntegration);
            this.registerOnPopState(directIntegration);
        },

        beforeOperation(operation, params) {
            // Search form won't disappear on its own after submit.
            document.querySelector('html').classList.remove('h-shows-search-form');

            return _merge(_cloneDeep(params), { properties: ['variants', ...params.properties] });
        },

        /**
         * @param item {BaseItem}
         * @param imageProperties {string[]}
         * @return {NormalizedVariant[]}
         */
        getNormalizedVariants(item, imageProperties) {
            try {
                const extraImages = Utils.getAllItemImages(item, imageProperties);

                return Object.values(JSON.parse(item.properties.variants)).map((variant) => {
                    const availableStock = 'inventory_quantity' in variant ? variant.inventory_quantity : null;

                    // Assume that the variant ID also serves as ordernumber.
                    return new NormalizedVariant(variant.id, variant.id, availableStock, extraImages);
                });
            } catch (e) {
                if (e instanceof SyntaxError) {
                    // JSON parsing failed - assuming missing variants.
                    return [];
                }

                throw e;
            }
        },

        supportsAddToCart: true,

        addToCart(normalizedVariant, quantity = 1) {
            const form = document.createElement('form');
            form.action = '/cart/add';

            FlUtils.appendHiddenFieldToForm(form, 'id', normalizedVariant.id);
            FlUtils.appendHiddenFieldToForm(form, 'quantity', quantity.toString());

            document.body.append(form);
            form.submit();
        },
    };
})();

FlUtils.extend(Shopify, BaseShopSystem);
export default Shopify;
