import FlUtils from '../../utils/utils';
import Utils from '../../../tscoba/src/Common/Utils.ts';
import BaseShopSystem from './base';
import { cloneDeep as _cloneDeep, merge as _merge } from 'lodash-es';
import { NormalizedVariant } from '../../../tscoba/src/Common/NormalizedVariant.ts';
import globalShopConfig from '../../config';

const Magento = (function () {
    /* Public */
    return {
        NAME: 'Magento',
        VERSION: 1,

        /**
         * @see base.js for doc
         */
        appendItemsAndSubmitForm(item, form, type) {
            /**
             * @see gambio.js for doc
             */
            if (this.isResultV3(type) && item.hasOwnProperty('block') && item.block !== 'suggest') {
                this.appendSpecificItemsToForm(item, form);
            } else {
                this.searchFieldElement.val(item.label);
            }

            this.submitForm(form);
        },

        /**
         * Appends shopsystem specific input fields to the form
         *
         * @param {JSON} item
         * @param {Object} form
         */
        appendSpecificItemsToForm(item, form) {
            // PRODUCT
            if (item.hasOwnProperty('identifier') && item.block === this.BLOCKS.PRODUCT) {
                this.appendInput(form, 'identifier', item.identifier);
                this.appendInput(form, 'q', item.label);
            }

            // CAT
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.CAT) {
                this.clearSearchFieldElement();
                this.appendInput(form, 'attrib[cat][0]', item.label);
            }

            // Vendor
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.VENDOR) {
                this.clearSearchFieldElement();
                this.appendInput(form, 'attrib[vendor][0]', item.label);
            }

            return this;
        },

        /**
         * A CSRF token (called "form key" in Magento lingo) must be submitted along with the product data
         * when adding something to the cart.
         * This information is not available by default, so the customer must supply it.
         * Customer can provide it like this:
         * <input name="form_key" type="hidden" value="<?php echo Mage::getSingleton('core/session')->getFormKey() ?>" />
         *
         */
        get supportsAddToCart() {
            return !!document.querySelector('input[name=form_key]');
        },

        beforeOperation(operation, params) {
            return _merge(_cloneDeep(params), { properties: ['available_stock', 'cart_id', ...params.properties] });
        },

        /**
         * @param item {BaseItem}
         * @param imageProperties {string[]}
         * @return {NormalizedVariant[]}
         */
        getNormalizedVariants(item, imageProperties) {
            const ordernumber = item.ordernumbers.length > 0 ? item.ordernumbers[0] : null;
            const stockInt = (baseItem) => parseInt(baseItem.properties['available_stock'], 10);
            const extraImages = Utils.getAllItemImages(item, imageProperties);

            if (!item.variants || item.variants.length === 0) {
                return item.properties['cart_id']
                    ? [
                          new NormalizedVariant(
                              item.properties['cart_id'],
                              ordernumber,
                              isNaN(stockInt(item)) ? null : stockInt(item),
                              extraImages
                          ),
                      ]
                    : [];
            }

            return item.variants.map(
                (variant) =>
                    new NormalizedVariant(
                        variant.properties['cart_id'] || variant.id,
                        variant.ordernumbers[0] || null,
                        isNaN(stockInt(variant)) ? null : stockInt(variant),
                        Utils.getAllItemImages(variant, imageProperties),
                        variant.url,
                        variant?.attributes,
                        globalShopConfig.instantFrontend.productListingPage.productCard.variantAttributes
                    )
            );
        },

        addToCart(normalizedVariant, quantity = 1) {
            const formClass = `fl-add-to-cart-${normalizedVariant.id}`;

            const form = document.createElement('form');
            form.action = `${window.location.origin}/checkout/cart/add/product/${normalizedVariant.id}`;
            form.method = 'POST';
            form.classList.add(formClass);

            FlUtils.appendHiddenFieldToForm(form, 'product', normalizedVariant.id);
            FlUtils.appendHiddenFieldToForm(form, 'item', normalizedVariant.id);
            FlUtils.appendHiddenFieldToForm(form, 'qty', quantity);
            FlUtils.appendHiddenFieldToForm(form, 'form_key', document.querySelector('input[name=form_key]').value);
            document.body.appendChild(form);
            form.submit();
        },
    };
})();

FlUtils.extend(Magento, BaseShopSystem);

export default Magento;
