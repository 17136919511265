import FlUtils from '../../utils/utils';
import BaseShopSystem from './base';

const XtcVeyton = (function () {
    /* Public */
    return {
        NAME: 'XtcVeyton',
        VERSION: 1,

        IDENTIFIER_PARAM: 'identifier',
        KEYWORD_PARAM: 'keywords',
        CAT_PARAM: 'attrib[cat][]',
        VENDOR_PARAM: 'attrib[vendor][]',

        /**
         * Appends shopsystem specific input fields to the form
         *
         * @param {JSON} item
         * @param {Object} form
         */
        appendSpecificItemsToForm(item, form) {
            // PRODUCT
            if (item.hasOwnProperty('label') && item.block === this.BLOCKS.PRODUCT) {
                this.appendInput(form, this.KEYWORD_PARAM, item.label);
            }

            // CAT
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.CAT) {
                this.appendInput(form, this.CAT_PARAM, item.label);
            }

            // Vendor
            if (item.hasOwnProperty('block') && item.block === this.BLOCKS.VENDOR) {
                this.appendInput(form, this.VENDOR_PARAM, item.label);
            }

            return this;
        },

        fixFormAction(formAction) {
            return formAction.replace(/\/search$/, '/vt_findologic');
        },
    };
})();

FlUtils.extend(XtcVeyton, BaseShopSystem);
export default XtcVeyton;
