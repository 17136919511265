import jQuery from './fl-jquery';
import constants from '../constants';
import config from '../config';
import { localStorage, logger, searchForm as SearchForm, userAgent, url as urlUtils, identity, func } from '@findologic/js-common';

/**
 * Extending Number Object - formats number for prices
 *
 * See http://stackoverflow.com/questions/149055/how-can-i-format-numbers-as-money-in-javascript?page=1&tab=votes#tab-top
 * E.g.: (123456789.12345).formatMoney(2, ',', '.'); // European
 * E.g.: (123456789.12345).formatMoney(2, '.', ','); // American
 *
 * @param {Number} c Sets the number of decimal points
 * @param {String} d Sets the separator for the decimal point
 * @param {String} t Sets the thousands separator
 *
 * @return Number
 */
// Not refactorying 3rd party code.
// eslint-disable-next-line complexity
Number.prototype.formatMoney = function (c, d, t) {
    let n = this;
    c = isNaN((c = Math.abs(c))) ? 2 : c;
    d = d == undefined ? ',' : d;
    t = t == undefined ? '.' : t;
    const s = n < 0 ? '-' : '';
    const i = `${parseInt((n = Math.abs(+n || 0).toFixed(c)), 10)}`;
    let j = (j = i.length) > 3 ? j % 3 : 0;

    return (
        s +
        (j ? i.substr(0, j) + t : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
        (c
            ? d +
              Math.abs(n - i)
                  .toFixed(c)
                  .slice(2)
            : '')
    );
};

const FlUtil = {
    Logger: logger,
    /**
     * Applies all properties to the child object.
     * Child has to define all methods and required properties from the parent object.
     *
     * @param {Object} child  The object to extend
     * @param {Object} parent The object from which the original extends
     */
    extend(child, parent) {
        // Actually needs to handle methods of the prototype.
        // eslint-disable-next-line guard-for-in
        for (const key in parent) {
            // Check if parent required properties are defined
            if (parent.hasOwnProperty('requiredProperties')) {
                let i = 0;
                const len = parent.requiredProperties.length;

                for (i = 0; i < len; i++) {
                    const requiredProp = parent.requiredProperties[i];

                    if (!child.hasOwnProperty(requiredProp)) {
                        // eslint-disable-next-line no-console
                        console.error(`Did not implement property: ${child.name}.${requiredProp}`);
                    }
                }

                // Check if child has parent method implemented
            } else if (typeof parent[key] === 'function') {
                if (!child.hasOwnProperty(key)) {
                    // eslint-disable-next-line no-console
                    console.error(`Did not implement method: ${child.name}.${key}`);
                }
            }

            // Copy parent properties and methods
            // But do not overwrite child attr
            if (!child.hasOwnProperty(key)) {
                child[key] = parent[key];
            }
        }
    },
};

// References to helpers that have been extracted to other projects are retained for compatibility.
FlUtil.parseQueryString = urlUtils.parseQueryString;
FlUtil.getQueryString = urlUtils.getQueryString;
FlUtil.getUsergrouphash = identity.getUsergrouphash;
FlUtil.errorTracker = {
    getIdentity: identity.getIdentity,
    createUUID: identity.createUUID,
};
FlUtil.localStorage = localStorage;
FlUtil.wrapCallbackWithTimeout = func.wrapCallbackWithTimeout;
FlUtil.undefinedToNoOp = func.undefinedToNoOp;

/**
 * Check if the viewport width is lower or equal to the autocompleteMobileMaxWidth property in the shopConfig.
 *
 * @param {string} shopConfig The URL to parse.
 * @returns {boolean} The device is mobile or not.
 */
FlUtil.isMobile = function (shopConfig) {
    return userAgent.isMobile(shopConfig);
};

/**
 * Check if Mobile Smart Suggest is active
 *
 * @param shopConfig
 * @returns {boolean} Mobile Smart Suggest is active.
 */
FlUtil.isMobileSmartSuggestOverlayActive = function (shopConfig) {
    try {
        return FlUtil.isMobile(shopConfig) && shopConfig.mobileSmartSuggest.config.enabled;
    } catch (e) {
        throw new Error("shopConfig doesn't have required params");
    }
};

/**
 * Overrides a currently engaged fallback mechanism, which is usually engaged in case of timeouts or errors.
 * By default, this method will refresh the page after disabling fallback.
 *
 * @param {boolean} [refreshPage] If false, the page will not refresh. Default is true.
 */
FlUtil.overrideFallback = function (refreshPage) {
    localStorage.removeItem(constants.FALLBACK_FLAG_NAME);

    if (refreshPage !== false) {
        window.location.reload(true);
    }
};

/**
 * Disables FINDOLOGIC temporarily to prevent inferior user experience in case of degraded performance or an
 * outage.
 */
FlUtil.setFallbackFlag = function () {
    localStorage.setItem(constants.FALLBACK_FLAG_NAME, new Date());
};

/**
 * Waits for a global property (belonging to the window object) to be set to anything other than undefined, and
 * calls the callback asynchronously.
 *
 * @public Part of the API used by integrations - backward compatibility mandatory.
 *
 * @param {string} propertyName Name of the property to wait for.
 * @param {function} callback Called once the property appears. The value of the property is passed as an argument.
 * @param {number} [timeout] Number of milliseconds before giving up to wait for the property. 5000 by default.
 */
FlUtil.waitForGlobal = function (propertyName, callback, timeout) {
    if (typeof timeout === 'undefined') {
        timeout = 5000;
    } else if (typeof timeout !== 'number') {
        throw new Error('timeout argument of FlUtil.waitForGlobal() must be a number, if provided.');
    }

    function isPropertySet() {
        return typeof window[propertyName] !== 'undefined';
    }

    if (isPropertySet()) {
        callback(window[propertyName]);
    } else {
        const waitInterval = setInterval(function () {
            if (isPropertySet()) {
                callback(window[propertyName]);
                clearInterval(waitInterval);
            }
        }, 100);

        // Stop checking for the property after timing out.
        setTimeout(function () {
            clearInterval(waitInterval);

            logger.error(`Timed out waiting for property window.${propertyName} to be set after ${timeout}ms.`);
        }, timeout);
    }
};

/**
 * Escapes HTML special characters to prevent XSS. Can be used for attribute values, as this helper escapes
 * double quotes as well.
 *
 * Courtesy of Chris Baker: http://stackoverflow.com/a/18750001/496143
 *
 * @public Part of the API used by integrations - backward compatibility mandatory.
 *
 * @param {string} raw The raw, unescaped string.
 */
FlUtil.escapeHtmlSpecialChars = function (raw) {
    return raw.replace(/[\u00A0-\u9999<>&"]/gim, function (character) {
        return `&#${character.charCodeAt(0)};`;
    });
};

/**
 * Does the equivalent of PHP's sprintf() or Java's String.format(), because JS can't.
 *
 * Example:
 *
 * FlUtil.formatString('The {1} will be the {0}.', 'last', 'first'); -> 'The first will be the last.'
 *
 * TODO: Currently, literal {\d+} are not supported, unless there are less arguments than the number. There is
 * no need for that right now, so this is not a priority.
 *
 * @public Part of the API used by integrations - backward compatibility mandatory.
 *
 * @param {string} str The string for format.
 * @return {string} The original string with all occurrences of numbers within curly braces removed.
 */
FlUtil.formatString = function (str) {
    for (let i = 0; i < arguments.length - 1; i++) {
        const pattern = new RegExp(`\\{${i}\\}`, 'gm');
        str = str.replace(pattern, arguments[i + 1]);
    }

    return str;
};

/**
 * Navigates to the given url or path.
 *
 * @param {string} url
 */
FlUtil.navigateTo = function (url) {
    window.location = url;
};

/**
 * Reloads the current page, because mocking the method directly doesn't appear to work.
 *
 * @param {boolean} force If true, the browser cache is overridden and the request is forcibly resent to the server.
 * Consistent with window.location.reload()'s API. Default is false-y.
 */
FlUtil.reload = function (force) {
    window.location.reload(force);
};

/**
 * Maps filters from the query object (if available) to an array of name/value pairs
 *
 * @param {{}} query
 * @returns {Array}
 */
FlUtil.mapQueryFiltersToArray = function (query) {
    const filters = [];

    if (query.hasOwnProperty('attrib')) {
        for (const filterName in query.attrib) {
            if (query.attrib[filterName].hasOwnProperty('min') && query.attrib[filterName].hasOwnProperty('max')) {
                filters.push({
                    name: filterName,
                    value: `${query.attrib[filterName].min} - ${query.attrib[filterName].max}`,
                });
            } else {
                for (const filterValueIndex in query.attrib[filterName]) {
                    if (!query.attrib[filterName].hasOwnProperty(filterValueIndex)) {
                        continue;
                    }

                    filters.push({
                        name: filterName,
                        value: query.attrib[filterName][filterValueIndex],
                    });
                }
            }
        }
    }

    return filters;
};

FlUtil.getSearchFieldElements = function () {
    let foundByIndividualSelector = false;

    const selector = config.searchFieldSelector;
    let searchFieldElement = jQuery(selector);

    /*
   The searchFieldSelector does not exists.
   Try to get it from known shopsystem specifig selectors
   */
    if (searchFieldElement.length) {
        foundByIndividualSelector = true;
    } else {
        logger.warn(`Could not find input element: ${selector} ... trying to use known selectors ...`);

        const knownSelectors = config.defaultSearchFieldSelectors;
        const len = knownSelectors.length;
        let i;

        for (i = 0; i < len; i++) {
            const knownSelector = knownSelectors[i];
            const currentSearchFieldElement = jQuery(knownSelector);

            if (currentSearchFieldElement.length) {
                logger.info(`Found input element by known selector: ${knownSelector}`);

                searchFieldElement = searchFieldElement.add(currentSearchFieldElement);
            }
        }

        // Still not found ...
        if (!searchFieldElement.length) {
            logger.error(`Could not find searchFieldElement! In config: ${selector}`);
        }
    }

    if (searchFieldElement.length > 1) {
        if (foundByIndividualSelector) {
            logger.warn(`Found more than one search field input, which matches the selector: ${selector}`);
        } else {
            logger.warn('Found more than one search field input, which matches known selectors');
        }
    }

    return searchFieldElement;
};

/**
 * Compares two objects if they are equal to each other bidirectionally, i.e. none of the objects can have more
 * or less properties than the other.
 *
 * Is NOT capable of comparing function implementations, but comparison of primitives is strict on types.
 *
 * Note that this is an unbounded recursive implementation. Don't use it for anything that might contain
 * cycles, if you're not prepared to catch the ensuing exception. For request comparison, this is safe, though,
 * because requests are cycle-free.
 *
 * @param {{}} left
 * @param {{}} right
 * @returns {boolean}
 */
FlUtil.equalObjects = function (left, right) {
    function compareArrays(left, right) {
        let areEqual = true;

        if (left.length !== right.length) {
            areEqual = false;
        } else {
            jQuery.each(left, function (index) {
                areEqual = left[index] === right[index];

                if (!areEqual) {
                    return false;
                }
            });
        }

        return areEqual;
    }

    function compareOneWay(left, right) {
        let areEqual = true;

        jQuery.each(Object.keys(left), function () {
            const property = this;

            if (!right.hasOwnProperty(property)) {
                areEqual = false;
            } else if (left instanceof Array && right instanceof Array) {
                areEqual = compareArrays(left, right);
            } else if (typeof left[property] === 'object' && typeof right[property] === 'object') {
                areEqual = compareOneWay(left[property], right[property]);
            } else {
                areEqual = left[property] === right[property];
            }

            if (!areEqual) {
                return false;
            }
        });

        return areEqual;
    }

    return compareOneWay(left, right) && compareOneWay(right, left);
};

/**
 * Unlike the jQuery method, this will not return an array of all found forms, but instead only the first
 * one.
 *
 * @param {jQuery} element
 * @return {jQuery}
 */
FlUtil.getClosestForm = function (element) {
    return jQuery(element.closest('form')[0]);
};

/**
 * Determine's the page's protocol, i.e. either "http" or "https".
 *
 * This helper exists primarily for mocking at test time, because the protocol can't be influenced while
 * testing protocol-sensitive functionality otherwise.
 *
 * @return string Protocol without a trailing comma.
 */
FlUtil.getProtocol = function () {
    return window.location.protocol.replace(':', '');
};

/**
 * @param params Contains form, searchFieldElements, focusedSearchField and lastFocusedSearchField
 * @returns {boolean}
 */
FlUtil.checkIfSearchFieldBelongsToForm = function (params) {
    const form = params.form;
    const allFields = params.searchFieldElements;
    const focusedSearchField = params.focusedSearchField;
    const lastFocusedSearchField = params.lastFocusedSearchField;

    if (allFields.length > 1) {
        // Is there a focused field then there is just one element
        const isFocusFieldFound = focusedSearchField.length === 1;
        // There is no focused field right now, so try to use the last field that lost focus
        if (lastFocusedSearchField && !isFocusFieldFound) {
            params.focusedSearchField = lastFocusedSearchField;
        }
        if (isFocusFieldFound && !jQuery.contains(form, focusedSearchField[0])) {
            // It's a different form, so stop it from sending
            return false;
        }
    }

    return true;
};

/**
 * @param {jQuery} fields That holds all found search-fields
 * @returns {jQuery} Either one field or all fields
 */
FlUtil.getFocusedSearchField = function (fields) {
    return jQuery(SearchForm.getFocusedSearchField(fields.toArray()));
};

/**
 * Returns a single searchFieldElement.
 * If more than one searchField have been found, the first input in the html will be returned.
 *
 * @returns {Object}
 */
FlUtil.getSingleSearchFieldElement = function () {
    let searchFieldElement = FlUtil.getSearchFieldElements();

    if (searchFieldElement.length > 1) {
        searchFieldElement = jQuery(searchFieldElement[0]);
        logger.warn(`Using the first matched search field input: ${searchFieldElement[0].outerHTML}`);
    }

    return searchFieldElement;
};

/**
 * Collect all the form values to be submitted with the autocomplete request
 *
 * @param {Object} form
 * @return Object
 */
FlUtil.getFormValues = function (form) {
    const inputs = {};

    jQuery(form.serializeArray()).each(function (i, j) {
        inputs[j.name] = j.value;
    });

    return inputs;
};

FlUtil.appendHiddenFieldToForm = function (form, name, value) {
    const field = document.createElement('input');
    field.setAttribute('type', 'hidden');
    field.setAttribute('name', name);
    field.setAttribute('value', value);
    form.appendChild(field);

    return field;
};

FlUtil.appendAllHiddenFieldsToForm = function (form, nameValueMap) {
    Object.entries(nameValueMap).forEach(([name, value]) => {
        FlUtil.appendHiddenFieldToForm(form, name, value);
    });
};

export default FlUtil;
