import FlUtils from '../../utils/utils';
import Shopware6 from './shopware_6';

const ShopwareApp = (function () {
    /* Public */
    return {
        NAME: 'Shopware App',
        VERSION: 1,
    };
})();

FlUtils.extend(ShopwareApp, Shopware6);
export default ShopwareApp;
